import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';
import { Route, Routes, useLocation } from 'react-router-dom';
import BlogDetails from './components/Inner/BlogDetails';
import WebinarDetails from './components/Inner/WebinarDetails';
import EventDetails from './components/Inner/EventDetails';
import Footer from './components/layout/Footer';
import MainNavBar from './components/layout/MainNavBar';
import NotFound from './components/layout/NotFound';
import TopBar from './components/layout/TopBar';
import { Helmet } from 'react-helmet-async';
import { homeData } from './data/homeData';
import { metaData } from './data/metaData';
const EventSection = React.lazy(() => import('./pages/EventSection'));
const TrainingSectionAll = React.lazy(() => import('./pages/TrainingSectionAll'));
const Home = React.lazy(() => import('./pages/Home'));
const Blog = React.lazy(() => import('./pages/Blog'));
const Events = React.lazy(() => import('./pages/Resources/Events'));
const Webinar = React.lazy(() => import('./pages/Resources/Webinar'));
const EventsRegistration = React.lazy(() => import('./pages/Resources/EventsRegistration'));
const WebinarRegistration = React.lazy(() => import('./pages/Resources/WebinarRegistration'));
const WhitePaper = React.lazy(()=> import('./pages/Whitepaper'));
const TermsCondition = React.lazy(()=> import('./pages/TermsCondition'));

// Cyber security

const CybeSecurity = React.lazy(() =>
  import('./pages/cyberSecurity/CyberSecurity')
);
const Siem = React.lazy(() => import('./pages/cyberSecurity/Siem'));
const Splunk = React.lazy(() => import('./pages/cyberSecurity/Splunk'));
const Toolkit = React.lazy(()=> import('./pages/cyberSecurity/Toolkit'))
const ToolkitDownload = React.lazy(()=> import('./pages/cyberSecurity/ToolkitDownload'))
const Soar = React.lazy(() => import('./pages/cyberSecurity/Soar'));
const VpnGateway = React.lazy(() => import('./pages/cyberSecurity/VpnGateway'));
const Devsecopps = React.lazy(() => import('./pages/cyberSecurity/DevSecOpps'));
const ZeroTrust = React.lazy(() => import('./pages/cyberSecurity/ZeroTrust'));
const DataPrivacy = React.lazy(() =>
  import('./pages/cyberSecurity/DataPrivacy')
);
const DataLossPrevention = React.lazy(() =>
  import('./pages/cyberSecurity/DataLossPrevention')
);
const RansomwarePrevention = React.lazy(() =>
  import('./pages/cyberSecurity/RansomwarePrevention')
);
const AdvancedPersistentThreatDetection = React.lazy(() =>
  import('./pages/cyberSecurity/AdvancedPersistentThreatDetection')
);
const GapAnalysis = React.lazy(() =>
  import('./pages/cyberSecurity/GapAnalysis')
);
const ApplicationSecurity = React.lazy(() =>
  import('./pages/cyberSecurity/ApplicationSecurityInnerPage')
);
const CloudAppSecurityTesting = React.lazy(() =>
  import('./pages/cyberSecurity/CloudAppSecurityTesting')
);
const SecurityAssessment = React.lazy(() =>
  import('./pages/cyberSecurity/SecurityAssessment')
);

const VulnerabilityAssessment = React.lazy(() =>
  import('./pages/cyberSecurity/VulnerabilityAssessment')
);

const NextgenerationFirewall = React.lazy(() =>
  import('./pages/cyberSecurity/NextgenerationFirewalls')
);

const DDoSProtectionInnerPage = React.lazy(() =>
  import('./pages/cyberSecurity/DDoSProtectionInnerPage')
);

const EndpointSecurityInnerPage = React.lazy(() =>
  import('./pages/cyberSecurity/EndpointSecurityInnerPage')
);
const WebMobileAppSecurityTesting = React.lazy(() =>
  import('./pages/cyberSecurity/WebMobileAppSecurityTesting')
);
const SandboxZeroInnerPage = React.lazy(() =>
  import('./pages/cyberSecurity/SandboxZeroInnerPage')
);
const WebApplicationFirewallInnerpage = React.lazy(() =>
  import('./pages/cyberSecurity/WebApplicationFirewallInnerpage')
);
const NetworkPenetrationTesting = React.lazy(() =>
  import('./pages/cyberSecurity/NetworkPenetrationTesting')
);
const WebsitePenetrationTesting = React.lazy(() =>
  import('./pages/cyberSecurity/WebsitePenetrationTesting')
);
const SecureAccessWireless = React.lazy(() =>
  import('./pages/cyberSecurity/AccessWireless')
);
const ContinuousMonitoring = React.lazy(() =>
  import('./pages/cyberSecurity/ContinuousMonitoring')
);
const InsiderThreatDataProtection = React.lazy(() =>
  import('./pages/cyberSecurity/InsiderThreatDataProtection')
);
const CyberAssuranceProgram = React.lazy(() =>
  import('./pages/cyberSecurity/CyberAssuranceProgram')
);
const InsiderRiskManagement = React.lazy(() =>
  import('./pages/cyberSecurity/InsiderRiskManagement')
);
const EmailSecurity = React.lazy(() =>
  import('./pages/cyberSecurity/EmailSecurity')
);
const DataClassification = React.lazy(() =>
  import('./pages/cyberSecurity/DataClassification')
);
const IdentityAndAccessManagement = React.lazy(() =>
  import('./pages/cyberSecurity/IdentityAndAccessManagement')
);
const IncidentResponse = React.lazy(() =>
  import('./pages/cyberSecurity/IncidentResponse')
);
const DigitalForensic = React.lazy(() =>
  import('./pages/cyberSecurity/DigitalForensic')
);
const ForensicMalwareAnalysis = React.lazy(() =>
  import('./pages/cyberSecurity/ForensicMalwareAnalysis')
);
// Cyber security end

// Cloud security start
const ApplicationGateway = React.lazy(() =>
  import('./pages/CloudSecurity/ApplicationGateway')
);
const AzureDDoSProtection = React.lazy(() =>
  import('./pages/CloudSecurity/AzureDDoSProtection')
);
const AzureInformationSecurity = React.lazy(() =>
  import('./pages/CloudSecurity/AzureInformationSecurity')
);
const CloudDataProtection = React.lazy(() =>
  import('./pages/CloudSecurity/CloudDataProtection')
);
const CloudProtectionServices = React.lazy(() =>
  import('./pages/CloudSecurity/CloudProtectionServices')
);
const CloudSecurityAdvisoryServices = React.lazy(() =>
  import('./pages/CloudSecurity/CloudSecurityAdvisoryServices')
);
const CloudSecurityAssessment = React.lazy(() =>
  import('./pages/CloudSecurity/CloudSecurityAssessment')
);
const CloudSecurityMonitoring = React.lazy(() =>
  import('./pages/CloudSecurity/CloudSecurityMonitoring')
);
const DataSecurity = React.lazy(() =>
  import('./pages/CloudSecurity/DataSecurity')
);
const Governance = React.lazy(() => import('./pages/CloudSecurity/Governance'));
const Microsoft365Security = React.lazy(() =>
  import('./pages/CloudSecurity/Microsoft365Security')
);
const NetworkSecurity = React.lazy(() =>
  import('./pages/CloudSecurity/NetworkSecurity')
);
const StorageSecurity = React.lazy(() =>
  import('./pages/CloudSecurity/StorageSecurity')
);

// Cloud Security End

// Data Center Start

const Analysis = React.lazy(() => import('./pages/DataCenter/Analysis'));
const ConsultingandEngineeringServices = React.lazy(() =>
  import('./pages/DataCenter/ConsultingandEngineeringServices')
);
const DataCenter = React.lazy(() => import('./pages/DataCenter/DataCenter'));
const DisasterRecoveryPlan = React.lazy(() =>
  import('./pages/DataCenter/DisasterRecoveryPlan')
);
const EnergyEfficiencyAssessments = React.lazy(() =>
  import('./pages/DataCenter/EnergyEfficiencyAssessments')
);
const InfrastructureDesign = React.lazy(() =>
  import('./pages/DataCenter/InfrastructureDesign')
);


/* Others */

const SOCaasServices = React.lazy(() =>
  import('./pages/Others/SOCaasService')
);
const MSSP = React.lazy(() =>
  import('./pages/Others/mssp')
);

const Managedit = React.lazy(() =>
  import('./pages/Others/manageit')
);
const CybersecurityServices = React.lazy(() =>
  import('./pages/Others/cybersecurity')
);

const Vciso = React.lazy(() =>
  import('./pages/Others/vciso')
);
const CloudSecurityServices = React.lazy(() =>
  import('./pages/Others/cloudSecurity')
);
const DigitalforensicServices = React.lazy(() =>
  import('./pages/Others/digitalforensicservices')
);

const Consultancy = React.lazy(() =>
  import('./pages/Others/consultancy')
);

const Aphish = React.lazy(() =>
  import('./pages/Products/a-phish')
);

const Asiem = React.lazy(() =>
  import('./pages/Products/a-siem')
);

const Asoc = React.lazy(() =>
  import('./pages/Products/a-soc')
);

const Assb = React.lazy(() =>
  import('./pages/Products/a-ssb')
);
const Agrc = React.lazy(() =>
  import('./pages/Products/a-grc')
);
const Asat = React.lazy(() =>
  import('./pages/Products/a-sat')
);
const Ashc = React.lazy(() =>
  import('./pages/Products/a-shc')
);
const Asecaas = React.lazy(() =>
  import('./pages/Products/a-secaas')
);
const DevSecOpps = React.lazy(() =>
  import('./pages/CloudAutomation/DevSecOps')
);
const Sentinel = React.lazy(() =>
  import('./pages/CloudAutomation/Sentinel')
);
const Xdr = React.lazy(() =>
  import('./pages/CloudAutomation/Xdr')
);
const IdentityandAccessManagement = React.lazy(() =>
  import('./pages/CloudAutomation/IdentityandAccessManagement')
);
const GovernanceAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/Governance')
);
const NetworkSecurityAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/NetworkSecurity')
);
const StorageSecurityAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/StorageSecurity')
);

const DataSecurityAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/DataSecurity')
);

const MicrosoftDefenderForCloudAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/MicrosoftDefenderForCloud')
);


const AzureActiveDirectoryAutomation = React.lazy(() =>
  import('./pages/CloudAutomation/AzureActiveDirectory')
);

const PartnersProgram = React.lazy(() =>
  import('./pages/Partners/partnersProgram')
);
const Whitepapers = React.lazy(() =>
  import('./pages/Resources/whitepapers')
);

const AboutAspire = React.lazy(() =>
  import('./pages/Company/AboutAspire')
);

const Contactus = React.lazy(() =>
  import('./pages/Company/contact')
);
const Careers = React.lazy(() =>
  import('./pages/Company/Careers')
);


const Complaince = React.lazy(() =>
  import('./pages/Compliance/Compliance')
);

//console.log(metaData.events.meta.title);

const AppRouter = () => {
  let location = useLocation();
  const homePagePath = location.pathname.split('/')[1] === '';
  const BlogPagePath = location.pathname.split('/')[2]==='blog';
  
  return (
    <Suspense
      fallback={
        <div
          style={{ height: '100vh' }}
          className="d-flex align-items-center justify-content-center text-danger"
        >
          <Spinner variant="danger" animation="grow" />
        </div>
      }
    >
      <div className='d-flex flex-column fixed-top'>
        <TopBar />
        <MainNavBar />
      </div>
      {/* <Helmet>
        <title>Aspire Tech Services & Solutions Corporation</title>
        <meta
          name="description"
          content="Aspire Tech Services and a Solutions is a leader of next-generation information technology (IT) services and solutions. Our mission is to enable superior returns on clients' technology investments through best-in-class industry solutions, domain expertise and global scale. The company is well known for its excellent challenge management skill on IT sectors which leads towards high customer satisfaction, best quality of work and add value to the commitment made to the clients."
        />
        <meta name="keywords" content="cyber security, cloud security" />
      </Helmet> */}
      <Routes>
        <Route path="/" element={
          <>
          <Helmet>
            <title>{homeData.meta.title}</title>
            <meta name="description" content={homeData.meta.desc}/>
            <meta name="keywords" content={homeData.meta.keyword} />
            <link rel="canonical" href={homeData.meta.canonical}/>
          </Helmet>
          <Home />
          </>
      }/>
        <Route path="/home" element={
           <>
           <Helmet>
             <title>{homeData.meta.title}</title>
             <meta name="description" content={homeData.meta.desc}/>
             <meta name="keywords" content="IT Services and Solutions Provider" />
             <link rel="canonical" href={homeData.meta.canonical}/>
           </Helmet>
           <Home />
           </>
        }/>
        <Route path="/resources/blog" element={
          <>
           <Helmet>
              <title>{metaData.blog.title}</title>
              <link rel="canonical" href={metaData.blog.canonical}/>
              <meta name="description" content={metaData.blog.meta.desc} />
              <meta name="keywords" content={metaData.blog.meta.keyword} />
           </Helmet>
           <Blog />
          </>
          } />
        <Route path="/resources/blog/*" element={<BlogDetails />} />
        <Route path="/resources/webinars" element={
           <>
            <Helmet>
              <title>{metaData.wabiners.title}</title>
              <link rel="canonical" href={metaData.wabiners.canonical}/>
              <meta name="description" content={metaData.wabiners.meta.desc} />
              <meta name="keywords" content={metaData.wabiners.meta.keyword} />
           </Helmet>
            <Webinar />
          </>
        } />
        <Route path="/resources/events" element={
          <>
           <Helmet>
              <title>{metaData.events.title}</title>
              <link rel="canonical" href={metaData.events.canonical}/>
              <meta name="description" content={metaData.events.meta.desc} />
              <meta name="keywords" content={metaData.events.meta.keyword} />
           </Helmet>
           <Events />
          </>
        }/>
        <Route path="/resources/webinars-details" element={<WebinarDetails />} />
        <Route path="/resources/webinars-registration" element={<WebinarRegistration />} />
        <Route path="/resources/events-registration" element={<EventsRegistration />} />
        <Route path="/event/*" element={<EventDetails />} />
        <Route path="/resources/training" element={
          <>
            <Helmet>
              <title>{metaData.training.title}</title>
              <link rel="canonical" href={metaData.training.canonical}/>
              <meta name="description" content={metaData.training.meta.desc} />
              <meta name="keywords" content={metaData.training.meta.keyword} />
           </Helmet>
            <TrainingSectionAll />
          </>
          } />
        <Route path="/resources/whitepaper" element={
             <>
             <Helmet>
               <title>{metaData.whitepaper.title}</title>
               <link rel="canonical" href={metaData.whitepaper.canonical}/>
               <meta name="description" content={metaData.whitepaper.meta.desc} />
               <meta name="keywords" content={metaData.whitepaper.meta.keyword} />
            </Helmet>
            <WhitePaper />
           </>
          }/>
        <Route
          path="/cybersecurity/cyber-security"
          element={<CybeSecurity />}
        />
        <Route path="/cybersecurity/siem" element={<Siem />} />
        <Route path="/cybersecurity/splunk" element={<Splunk />} />
        <Route path="/resources/free-cybersecurity-resource-kits/cybersecurity-awareness-month-kit" element={<Toolkit />} />
        <Route path="/resources/free-cybersecurity-resource-kits/cybersecurity-awareness-month-kit/download" element={<ToolkitDownload />} />
        <Route path="/cybersecurity/soar" element={<Soar />} />
        <Route path="/cybersecurity/vpn-gateway" element={<VpnGateway />} />
        <Route path="/cybersecurity/zero-trust" element={<ZeroTrust />} />
        <Route
          path="/cybersecurity/ransomware-prevention"
          element={<RansomwarePrevention />}
        />
        <Route
          path="/cybersecurity/insider-risk-management"
          element={<InsiderRiskManagement />}
        />
        <Route path="/cybersecurity/devsecops" element={<Devsecopps />} />
        <Route
          path="/cybersecurity/apt-detection"
          element={<AdvancedPersistentThreatDetection />}
        />
        <Route path="/cybersecurity/gap-analysis" element={<GapAnalysis />} />
        <Route path="/cybersecurity/data-privacy" element={<DataPrivacy />} />
        <Route
          path="/cybersecurity/next-generation-firewalls"
          element={<NextgenerationFirewall />}
        />
        <Route
          path="/cybersecurity/email-security"
          element={<EmailSecurity />}
        />
        <Route
          path="/cybersecurity/data-classification"
          element={<DataClassification />}
        />
        <Route
          path="/cybersecurity/data-loss-prevention"
          element={<DataLossPrevention />}
        />
        <Route
          path="/cybersecurity/application-security"
          element={<ApplicationSecurity />}
        />
        <Route
          path="/cybersecurity/cloud-app-security-testing"
          element={<CloudAppSecurityTesting />}
        />
        <Route
          path="/cybersecurity/security-assessment"
          element={<SecurityAssessment />}
        />
        <Route
          path="/cybersecurity/ddos-protection"
          element={<DDoSProtectionInnerPage />}
        />
        <Route
          path="/cybersecurity/vulnerability-assessment"
          element={<VulnerabilityAssessment />}
        />
        <Route
          path="/cybersecurity/endpoint-security"
          element={<EndpointSecurityInnerPage />}
        />
        <Route
          path="/cybersecurity/web-mobile-application-security"
          element={<WebMobileAppSecurityTesting />}
        />
        <Route
          path="/cybersecurity/sandbox"
          element={<SandboxZeroInnerPage />}
        />
        <Route
          path="/cybersecurity/web-application-firewall"
          element={<WebApplicationFirewallInnerpage />}
        />
        <Route
          path="/cybersecurity/network-penetration-testing"
          element={<NetworkPenetrationTesting />}
        />
        <Route
          path="/cybersecurity/website-penetration-testing"
          element={<WebsitePenetrationTesting />}
        />

        <Route
          path="/cybersecurity/secure-access-wireless"
          element={<SecureAccessWireless />}
        />
        <Route
          path="/cybersecurity/continuous-monitoring"
          element={<ContinuousMonitoring />}
        />
        <Route
          path="/cybersecurity/insider-threat-data-protection"
          element={<InsiderThreatDataProtection />}
        />
        <Route
          path="/cybersecurity/incident-response"
          element={<IncidentResponse />}
        />
        <Route
          path="/cybersecurity/id-access-management"
          element={<IdentityAndAccessManagement />}
        />
        <Route
          path="/cybersecurity/digital-forensic"
          element={<DigitalForensic />}
        />
        <Route
          path="/cybersecurity/forensic-malware-analysis"
          element={<ForensicMalwareAnalysis />}
        />
        <Route
          path="/cybersecurity/cyber-assurance-program"
          element={<CyberAssuranceProgram />}
        />
        <Route
          path="/resources/whitepaper"
          element={<Whitepapers />}
        />

        {/* Cyber security end */}

        {/* Cloud Security */}

        <Route
          path="/cloudSecurity/application-gateway"
          element={<ApplicationGateway />}
        />
        <Route
          path="/cloudSecurity/azure-information-security"
          element={<AzureInformationSecurity />}
        />
        <Route
          path="/cloudSecurity/azure-ddos-protection"
          element={<AzureDDoSProtection  />}
        />
        <Route
          path="/cloudSecurity/cloud-data-protection"
          element={<CloudDataProtection />}
        />
        <Route
          path="/cloudSecurity/cloud-protection-services"
          element={<CloudProtectionServices />}
        />
        <Route
          path="/cloudSecurity/cloud-security-advisory-services"
          element={<CloudSecurityAdvisoryServices />}
        />
        <Route
          path="/cloudSecurity/cloud-security-assessment"
          element={<CloudSecurityAssessment />}
        />
        <Route
          path="/cloudSecurity/cloud-security-monitoring"
          element={<CloudSecurityMonitoring />}
        />
        <Route path="/cloudSecurity/data-security" element={<DataSecurity />} />
        <Route path="/cloudSecurity/governance" element={<Governance />} />
        <Route
          path="/cloudSecurity/microsoft-365-security"
          element={<Microsoft365Security />}
        />
        <Route
          path="/cloudSecurity/network-security"
          element={<NetworkSecurity />}
        />
        <Route
          path="/cloudSecurity/storage-security"
          element={<StorageSecurity />}
        />

        {/* Cloud Security End*/}

        {/* Compliance Start*/}
        
        <Route
          path="/compliance/compliance"
          element={<Complaince />}
        />
        
        {/* Compliance End*/}

        {/* Data center Start*/}

        <Route path="/DataCenter/analysis" element={<Analysis />} />
        <Route
          path="/DataCenter/consulting-and-engineering-services"
          element={<ConsultingandEngineeringServices />}
        />
        <Route path="/DataCenter/data-center" element={<DataCenter />} />
        <Route
          path="/DataCenter/disaster-recovery-plan"
          element={<DisasterRecoveryPlan />}
        />
        <Route
          path="/DataCenter/energy-efficiency-assessments"
          element={<EnergyEfficiencyAssessments />}
        />
        <Route
          path="/DataCenter/infrastructure-design"
          element={<InfrastructureDesign />}
        />

        {/* Data center END*/}
        
        {/* Others */}
        
        <Route
          path="/managedservice/SOCaasServices"
          element={<SOCaasServices />}
        />
        <Route
          path="/managedservice/mssp"
          element={<MSSP />}
        />
        
        <Route
          path="/managedservice/managedit"
          element={<Managedit />}
        />
        <Route
          path="/managedservice/cybersecurity"
          element={<CybersecurityServices />}
        />
        <Route
          path="/managedservice/vciso"
          element={<Vciso />}
        />
        <Route
          path="/managedservice/cloudsecurity"
          element={<CloudSecurityServices />}
        />
        <Route
          path="/managedservice/digitalforensic"
          element={<DigitalforensicServices />}
        />
        <Route
          path="/managedservice/consultancy"
          element={<Consultancy />}
        />
        <Route
          path="/products/a-phish"
          element={<Aphish />}
        />
        <Route
          path="/products/a-siem"
          element={<Asiem />}
        />
        
        <Route
          path="/products/a-soc"
          element={<Asoc />}
        />
        
        <Route
          path="/products/a-ssb"
          element={<Assb />}
        />
        
        <Route
          path="/products/a-grc"
          element={<Agrc />}
        />
        <Route
          path="/products/a-sat"
          element={<Asat />}
        />
        <Route
          path="/products/a-shc"
          element={<Ashc />}
        />
        <Route
          path="/products/A-SECaaS"
          element={<Asecaas />}
        />
        <Route
          path="/cloudautomation/DEVSECOPS"
          element={<DevSecOpps />}
        />
        <Route
          path="/cloudautomation/MicrosoftSentinel"
          element={<Sentinel />}
        />
        <Route
          path="/cloudautomation/IdentityandAccessManagement"
          element={<IdentityandAccessManagement />}
        />
        <Route
          path="/cloudautomation/XDR"
          element={<Xdr />}
        />
        <Route
          path="/cloudautomation/Governance"
          element={<GovernanceAutomation />}
        />
        <Route
          path="/cloudautomation/NetworkSecurity"
          element={<NetworkSecurityAutomation />}
        />
        <Route
          path="/cloudautomation/StorageSecurity"
          element={<StorageSecurityAutomation />}
        />
        <Route
          path="/cloudautomation/DataSecurity"
          element={<DataSecurityAutomation />}
        />
        <Route
          path="/cloudautomation/MicrosoftDefenderForCloud"
          element={<MicrosoftDefenderForCloudAutomation />}
        />
        
        <Route
          path="/cloudautomation/AzureActiveDirectory"
          element={<AzureActiveDirectoryAutomation />}
        />
        
        <Route
          path="/partners/partner-program"
          element={
            <>
              <Helmet>
                <title>{metaData.partner.title}</title>
                <link rel="canonical" href={metaData.partner.canonical}/>
                <meta name="description" content={metaData.partner.meta.desc} />
                <meta name="keywords" content={metaData.partner.meta.keyword} />
            </Helmet>
            <PartnersProgram />
            </>
          }
        />
        <Route
          path="/company/about"
          element={
            <>
            <Helmet>
              <title>{metaData.aboutaspire.title}</title>
              <link rel="canonical" href={metaData.aboutaspire.canonical}/>
              <meta name="description" content={metaData.aboutaspire.meta.desc} />
              <meta name="keywords" content={metaData.aboutaspire.meta.keyword} />
           </Helmet>
           <AboutAspire />
          </>
          }
        />
        <Route
          path="/company/contact"
          element={
            <>
            <Helmet>
              <title>{metaData.contact.title}</title>
              <link rel="canonical" href={metaData.contact.canonical}/>
              <meta name="description" content={metaData.contact.meta.desc} />
              <meta name="keywords" content={metaData.contact.meta.keyword} />
            </Helmet>
            <Contactus />
            </>
          }
        />
        <Route
          path="/company/Careers"
          element={
            <>
            <Helmet>
              <title>{metaData.careers.title}</title>
              <link rel="canonical" href={metaData.careers.canonical}/>
              <meta name="description" content={metaData.careers.meta.desc}/>
              <meta name="keywords" content={metaData.careers.meta.keyword} />
           </Helmet>
           <Careers />
           </>
        
        }
        />
        <Route
          path="/termscondition"
          element={<TermsCondition />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    {/*  {!homePagePath && !BlogPagePath && (
        <>
          <EventSection />
          <TrainingSection />
        </>
      )}*/}
      <Footer />
    </Suspense>
  );
};

export default AppRouter;
