import React from "react";
import { Container, Card, Image, Row, Col, Badge } from "react-bootstrap";
import { BsFillClockFill } from "react-icons/bs";
import { GiBlackBook } from "react-icons/gi";
import { Link } from "react-router-dom";

const BlogSideNav = ({items}) => {
   console.log(items.tag);
  const { title,image_link,publish_date,publish_time,author_name,description,category }=items;
  const cardStyle = {
        position: "relative",
        display: "flex",
        flexDirection: "column",
        minWidth: "0",
        wordWrap: "break-word",
        backgroundColor: "#fdfdfd",
        backgroundClip: "border-box",
        border: "1px solid rgb(234 243 246)",
        borderRadius: "0rem"
  }
  const blogImage = {
        width : "100%",
        height: "190px"
  }

  const dateString = publish_date; // "2024-05-21"
  const newDate = new Date(dateString); // Parse the date string
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(newDate);
  console.log(formattedDate);


  return (
    <Container fluid className="my-3 mx-0 px-0">
      <Card shadow="sm" className="rounded-md" style={cardStyle}>
        <Row>
          <Col xs sm md lg xl xxl="12">
            <Image src={image_link} style={blogImage} alt={title} className="rounded-md"/>
          </Col>
        </Row>
        <Row className="">
            <Col xs sm md lg xl xxl="12">
                <div className="d-flex">
                    <p className="text-dark d-flex align-items-center gap-2 px-4 pt-4 pb-0 mb-0" style={{fontSize: "12px",}}>
                    {/* <BsFillClockFill size={18} className="items-center" />  */}
                    {formattedDate} 
                    {author_name && 
                      <span>|</span>
                    }
                    {author_name}
                    </p>
                </div>
            </Col>
        </Row>
        <Row className="">
            <Col xs sm md lg xl xxl="12">
                {category ? (<div>
                    <p className="text-dark d-flex gap-2 px-4">
                        <GiBlackBook size={18} className="items-center" /> {category}
                    </p>
                </div>) : ('')
                }

            </Col>
        </Row>
          <Row>
              <Col xs sm md lg xl xxl="12" className="mt-2">
                <h3 className="text-dark px-4 mb-5" style={{fontSize: "18px", fontWeight: "600"}}>
                  <Link to={`${title}`} className="text-dark link-underling">{title}</Link>
                </h3>
              </Col>
            </Row>

      </Card>
    </Container>
  );
};

export default BlogSideNav;
