import siem from '../assets/images/solutions/CyberSecurity/Seim.svg';
import vapt from '../assets/images/solutions/CyberSecurity/VAPT.svg';
import xdr from '../assets/images/solutions/CyberSecurity/XDR.svg';
import zero_trust from '../assets/images/solutions/CyberSecurity/Zero-Trust.svg';
import Data_Loss_Prevention from '../assets/images/solutions/CyberSecurity/Data-Loss-Prevention.svg';
import DDoS_Protection from '../assets/images/solutions/CyberSecurity/DDoS-Protection.png';
import DevSecOps from '../assets/images/solutions/CyberSecurity/DevSecOps.svg';
import Email_Security from '../assets/images/solutions/CyberSecurity/Email-Security.png';
import Insider_Risk_Manager from '../assets/images/solutions/CyberSecurity/Insider-Risk-Manager.svg';
import Microsoft_Defender from '../assets/images/solutions/CyberSecurity/Microsoft-Defender.svg';
import Ransomware_Prevention from '../assets/images/solutions/CyberSecurity/Ransomware-Prevention.png';
import Microsoft_Sentinel from '../assets/images/solutions/CloudSecurity/Microsoft-Sentinel.png';
import vpn_gateway from '../assets/images/solutions/CloudSecurity/VPN-Gateway.svg';
import Azure_Information_Protection from '../assets/images/solutions/CloudSecurity/Azure-Information-Protection.svg';
import digital_evidence from '../assets/images/solutions/DigitalForensic/Digital-Evidence-Recovery.svg';
import data_breach from '../assets/images/solutions/DigitalForensic/Data-Breach-Forensic.png';
import cyber_fraud from '../assets/images/solutions/DigitalForensic/Cyber-Fraud-Investigation.png';
import Incident_Response from '../assets/images/solutions/DigitalForensic/Incident-Response.svg';
import E_Discovery from '../assets/images/solutions/DigitalForensic/E-Discovery.png';
import SOC_As_a_Service from '../assets/images/solutions/ManagedServices/SOC-As-a-Service.png';
import vCISO_Services from '../assets/images/solutions/ManagedServices/vCISO-Services.png';
import Cloud_Security_Services from '../assets/images/solutions/ManagedServices/Cloud-Security-Services.png';
import Digital_Forensic_Services from '../assets/images/solutions/ManagedServices/Digital-Forensic-Services.png';
import MSSP_Services from '../assets/images/solutions/ManagedServices/MSSP-Services.svg';
import socIcon1 from '../assets/icons/soc-icon1.svg';
import socIcon2 from '../assets/icons/soc-icon2.svg';
import socIcon3 from '../assets/icons/soc-icon3.svg';
import socIcon4 from '../assets/icons/soc-icon4.svg';
import socIcon5 from '../assets/icons/soc-icon5.svg';
import product1 from '../assets/images/product1.svg';
import product2 from '../assets/images/product2.svg';
import product3 from '../assets/images/product3.svg';
import product4 from '../assets/images/product4.svg';
import product5 from '../assets/images/product5.svg';
import product6 from '../assets/images/product6.png';
import product7 from '../assets/images/product7.svg';
import product8 from '../assets/images/product8.svg';
import A_PISH from '../assets/images/products/A-PISH/What-Is-Phishing.png';
import A_SAT from '../assets/images/products/A-SAT/Cyber-Security-Awareness-Program-icon.png';
import A_SHC from '../assets/images/products/A-SHC/What-Is-a-Security-Health-Check.png';
import trainingIcon1 from '../assets/icons/tc-icon1.svg';
import trainingIcon2 from '../assets/icons/tc-icon2.svg';
import trainingIcon3 from '../assets/icons/tc-icon3.svg';
import trainingIcon4 from '../assets/icons/tc-icon4.svg';
import SCA1 from '../assets/images/SecurityComplianceAdvisory/big/PCI-DSS.svg';
import SCA2 from '../assets/images/SecurityComplianceAdvisory/big/SWIFT.png';
import SCA3 from '../assets/images/SecurityComplianceAdvisory/big/GDPR.png';
import SCA4 from '../assets/images/SecurityComplianceAdvisory/big/ISO-27001.png';
import SCA1icon from '../assets/images/SecurityComplianceAdvisory/small/PCI-DSS.svg';
import SCA2icon from '../assets/images/SecurityComplianceAdvisory/small/SWIFT.png';
import SCA3icon from '../assets/images/SecurityComplianceAdvisory/small/GDPR.png';
import SCA4icon from '../assets/images/SecurityComplianceAdvisory/small/ISO-27001.svg';
import cloudIcon1 from '../assets/images/clouds/DevSecOps-icon.svg';
import cloudIcon2 from '../assets/images/clouds/Microsoft-Sentinel-icon.svg';
import cloudIcon3 from '../assets/images/clouds/XDR-icon.svg';
import cloudIcon4 from '../assets/images/clouds/Microsoft-Defender-For-Cloud-icon.svg';
import cloudIcon5 from '../assets/images/clouds/Azure-Active-Directory-icon.svg';
import cloudIcon6 from '../assets/images/clouds/Network-Security-icon.svg';
import cloudIcon7 from '../assets/images/clouds/Governance-icon.svg';
import cloudIcon8 from '../assets/images/clouds/Storage-Security-icon.svg';
import cloudIcon9 from '../assets/images/clouds/Data-Security-icon.svg';
import cloudItem1 from '../assets/images/clouds/DevSecOps.svg';
import cloudItem2 from '../assets/images/clouds/Microsoft-Sentinel.svg';
import cloudItem3 from '../assets/images/clouds/XDR.svg';
import cloudItem4 from '../assets/images/clouds/Microsoft-Defender For.svg';
import cloudItem5 from '../assets/images/clouds/Azure-Active-Directory.svg';
import cloudItem6 from '../assets/images/clouds/Network-Security.svg';
import cloudItem7 from '../assets/images/clouds/Governance.svg';
import cloudItem8 from '../assets/images/clouds/Storage-Security.svg';
import cloudItem9 from '../assets/images/clouds/Data-Security.svg';
import partner1 from '../assets/images/partners/Splunk.png';
import partner2 from '../assets/images/partners/CyberArk.png';
import partner3 from '../assets/images/partners/Rapid7.png';
import partner4 from '../assets/images/partners/Symantec.png';
import partner5 from '../assets/images/partners/Tenable.png';
import partner6 from '../assets/images/partners/Thycotic.png';
import partner7 from '../assets/images/partners/Centrify.png';
import partner8 from '../assets/images/partners/Dell RSA.png';
import partner9 from '../assets/images/partners/CheckPoint.png';
import partner10 from '../assets/images/partners/HCL.png';
import partner11 from '../assets/images/partners/Fortinet.png';
import partner12 from '../assets/images/partners/LogRhythm.png';
import partner13 from '../assets/images/partners/Veracode.png';
import partner14 from '../assets/images/partners/Barracuda.png';
import partner15 from '../assets/images/partners/Sophos.png';
import partner16 from '../assets/images/partners/SolarWinds.png';
import partner17 from '../assets/images/partners/SonicWALL.png';
import partner18 from '../assets/images/partners/Okta.png';
import partner19 from '../assets/images/partners/Exabeam.png';
import partner20 from '../assets/images/partners/Algosec.png';
import partner21 from '../assets/images/partners/RangeForce.png';
import partner22 from '../assets/images/partners/AttackIQ.png';
import partner23 from '../assets/images/partners/DFLabs.png';
import partner24 from '../assets/images/partners/Invicti.png';
import partner25 from '../assets/images/partners/Microsoft.png';
import partner26 from '../assets/images/partners/Kaspersky.png';
import partner27 from '../assets/images/partners/Veeam.png';
import partner28 from '../assets/images/partners/Splunk.png';
import partner29 from '../assets/images/partners/BlueVoyant.png';
import partner30 from '../assets/images/partners/Alertlogic-SOC.png';
import partner31 from '../assets/images/partners/ElasticSearch.png';
import partner32 from '../assets/images/partners/BeyondTrust.png';
import partner33 from '../assets/images/partners/Sumologic.png';
import partner34 from '../assets/images/partners/PingIdentity.png';
import partner35 from '../assets/images/partners/Rapid7.png';
import partner36 from '../assets/images/partners/Thales.png';
import partner37 from '../assets/images/partners/Dell RSA.png';
import partner38 from '../assets/images/partners/Paloalto.png';
import partner39 from '../assets/images/partners/OneLogin.png';
import partner40 from '../assets/images/partners/RedHat.png';
import partner41 from '../assets/images/partners/Qualys.png';
import partner42 from '../assets/images/partners/Searchinform.png';
import partner43 from '../assets/images/partners/CoSoSys.png';
import partner44 from '../assets/images/partners/InfoBlox.png';
import partner45 from '../assets/images/partners/TripWire.png';
import partner46 from '../assets/images/partners/LogRhythm.png';
import partner47 from '../assets/images/partners/Okta.png';
import partner48 from '../assets/images/partners/Algosec.png';
import partner49 from '../assets/images/partners/Zimperium.png';
import partner50 from '../assets/images/partners/Magnet-Forensics.png';
import partner51 from '../assets/images/partners/Guidance-Software.png';
import partner52 from '../assets/images/partners/Passware.png';
import partner53 from '../assets/images/partners/Elcomsoft.png';
import partner54 from '../assets/images/partners/Cellebrite.png';
import partner55 from '../assets/images/partners/Belkasoft.png';
import partner56 from '../assets/images/partners/Atola.png';
import partner57 from '../assets/images/partners/Hex-Rays.png';
import partner58 from '../assets/images/partners/AceLab.png';
import partner59 from '../assets/images/partners/AccessData.png';
import partner60 from '../assets/images/partners/Maltego.png';
import partner61 from '../assets/images/partners/Salvation-Data.png';
import partner62 from '../assets/images/partners/Sumuri.png';
import partner63 from '../assets/images/partners/TechData.png';
import partner64 from '../assets/images/partners/Zecops.png';
import partner65 from '../assets/images/partners/Teeltech.png';
import partner66 from '../assets/images/partners/ISFMT.png';
import partner67 from '../assets/images/partners/CAINE.png';
import partner68 from '../assets/images/partners/X-WAYS.png';
import partner69 from '../assets/images/partners/Paraben.png';
import partner70 from '../assets/images/partners/SleuthKit.png';
import partner71 from '../assets/images/partners/SANS.png';
import partner72 from '../assets/images/partners/Wireshark.png';
import SOC1 from '../assets/images/partners/SOC1.svg';
import SOC2 from '../assets/images/partners/SOC2.webp';
import SOC3 from '../assets/images/partners/SOC3.webp';
import SOC4 from '../assets/images/partners/SOC4.webp';
import SOC5 from '../assets/images/partners/SOC5.webp';
import SOC6 from '../assets/images/partners/SOC6.webp';
import SOC7 from '../assets/images/partners/SOC7.svg';
import SOC8 from '../assets/images/partners/SOC8.webp';
import SOC9 from '../assets/images/partners/SOC9.webp';
import SOC10 from '../assets/images/partners/SOC10.webp';
import SOC11 from '../assets/images/partners/SOC11.svg';
import SOC12 from '../assets/images/partners/SOC12.svg';
import SOC13 from '../assets/images/partners/SOC13.svg';
import SOC14 from '../assets/images/partners/SOC14.svg';
import SOC15 from '../assets/images/partners/SOC15.svg';

export const homeData = {
    meta: {
        title: 'Aspire Tech: Premier IT Services and Solutions Provider',
        desc: `Aspire Tech provides superior IT services and solutions to streamline and enhance your business operations.`,
        canonical: 'https://www.aspiretss.com/',
        keyword: 'IT Services and Solutions Provider',
    },
    exploreSecurity: [{
            id: 1,
            title: 'Security Awareness',
            content: 'By assisting your staff, you may extend your security awareness program outside the office.',
            btnText: 'Request for Security Awareness',
            icon: A_SAT,
            link: 'a-sat'
        },
        {
            id: 2,
            title: 'Security Health Check',
            content: 'Cyber Security Health Check is designed to help business understand their security risk position. ',
            btnText: 'Request for Health Checkup',
            link: 'a-shc',
            icon: A_SHC,
        },
        {
            id: 3,
            title: 'Phishing Simulation',
            content: 'Phishing simulators guarantee that your staff are aware of phishing and threats.',
            btnText: 'Request for Phishing Simulation',
            link: 'a-phish',
            icon: A_PISH,
        },
    ],
    securityOperations: [{
            id: 1,
            title: 'SOC Center',
            icon: socIcon1,
            link: '/managedservice/SOCaasServices'
        },
        {
            id: 2,
            title: 'A-SIEM',
            icon: socIcon2,
            link: '/products/a-siem'
        },
        {
            id: 3,
            title: 'SOAR',
            icon: socIcon3,
            link: '/cybersecurity/soar'
        },
        {
            id: 4,
            title: 'Threat Intelligence',
            icon: socIcon4,
            link: '/managedservice/SOCaasServices#incident-response'
        },
        {
            id: 5,
            title: 'Digital Forensic',
            icon: socIcon5,
            link: '/cybersecurity/digital-forensic'
        },
    ],

    aspireProducts: [{
            id: 1,
            title: 'A-SIEM',
            content: "Aspire Security Information and Event Management (A-SIEM) is a set of tools and services offering a holistic view of an organization's ",
            icon: product1,
            url: 'https://youtu.be/inWWhr5tnEA',
            section: 'Aspire Products',
            topic: 'A-SIEM'
        },
        {
            id: 2,
            title: 'A-GRC',
            content: "Aspire Governance, risk and compliance (A-GRC) refers to a strategy for managing an organization's overall governance, enterprise risk ",
            icon: product2,
            url: 'https://youtu.be/GX_XsdNv1PY',
            section: 'Aspire Products',
            topic: 'A-GRC'
        },
        {
            id: 3,
            title: 'A-SAT',
            content: 'Aspire Security Awareness Training (A-SAT) is a strategy used by IT and security professionals to prevent and mitigate end user risk',
            icon: product3,
            url: 'https://youtu.be/hh4fPUXYm5A',
            section: 'Aspire Products',
            topic: 'A-SAT'
        },
        {
            id: 4,
            title: 'A-PHISH',
            content: 'Phishing simulation tools protect your company by exposing employees to phony phishing emails and observing how they respond.',
            icon: product4,
            url: 'https://youtu.be/C8-84MQY39c',
            section: 'Aspire Products',
            topic: 'A-PHISH'
        },
        {
            id: 5,
            title: 'A-SOC',
            content: 'Aspire Security Operations Center (A-SOC) is a physical room where analysts responsible for detecting, preventing,investigating, and responding',
            icon: product5,
            url: 'https://youtu.be/M3Lx_oE_n04',
            section: 'Aspire Products',
            topic: 'A-SOC'
        },
        {
            id: 6,
            title: 'A-SSB',
            content: "Aspire Security Score Board (A-SSB) calculates security ratings on an organization's security and measures cyber risk trough cpmpliance",
            icon: product6,
            url: 'https://youtu.be/inWWhr5tnEA',
            section: 'Aspire Products',
            topic: 'A-SSB'
        },
        {
            id: 7,
            title: 'A-SHC',
            content: 'Aspire Security Health Checks (A-SHC) Identify,Verify, Compare, Define and validate your organization’s security strengths and security',
            icon: product7,
            url: 'https://youtu.be/C8-84MQY39c',
            section: 'Aspire Products',
            topic: 'A-SHC'
        },
        {
            id: 8,
            title: 'A-SECaaS',
            content: 'Aspire Security as a Service (A-SECaaS) provides products and services to reduces upfront costs of security hardware, outsources and ',
            icon: product8,
            url: 'https://youtu.be/GX_XsdNv1PY',
            section: 'Aspire Products',
            topic: 'A-SECaaS'
        },
    ],
    trainingAndCertifications: [{
            id: 1,
            title: 'Security Awareness Training',
            content: ['Intro to Security Awareness', 'Computer Security'],
            icon: trainingIcon1,
            link: "https://aspireelearning.com/"
        },
        {
            id: 2,
            title: 'Compliance Training',
            content: ['PCI Compliance', 'Data Protection'],
            icon: trainingIcon2,
            link: "https://aspireelearning.com/Privacy-Training/PCI_Compliance"

        },
        {
            id: 3,
            title: 'Cloud Training',
            content: ['Microsoft 365', 'Azure  Sentinel'],
            icon: trainingIcon3,
            link: "https://aspireelearning.com/Microsoft-Azure-Fundamental"

        },
        {
            id: 4,
            title: 'EC Council / (ISC)2 / ISACA',
            content: [
                "ISACA's (CGEIT) certification",
                'Certified Network Defender (CND)',
            ],
            icon: trainingIcon4,
            link: "https://aspireelearning.com/EC-Council-CCISO"

        },
    ],
    services: {
        soc: [{
                id: 1,
                title: 'Security monitoring',
                content: [
                    'Real-time monitoring',
                    'Pro-active hunting',
                    'Alert analysis and triage',
                ],
                link: 'managedservice/SOCaasServices#security-monitoring'
            },
            {
                id: 2,
                title: 'Compliance reporting',
                content: [
                    'Workflow integration',
                    'Associated with a regular to-do list',
                ],
                link: 'managedservice/SOCaasServices#compliance'
            },
            {
                id: 3,
                title: 'Incident response',
                content: [
                    'Real-time monitoring',
                    'Pro-active hunting',
                    'Alert analysis and triage',
                ],
                link: 'managedservice/SOCaasServices#incident-response'
            },
            {
                id: 4,
                title: 'Vulnerability scanning',
                content: [
                    'Continuous network monitoring',
                    'Log data collection/correlation',
                    'Cloud monitoring',
                ],
                link: 'managedservice/SOCaasServices#vulnerability-scanning'
            },
            {
                id: 5,
                title: 'Threat intelligence',
                content: ['Tactical intelligence', 'Strategic intelligence'],
                link: 'managedservice/SOCaasServices#threat-intelligence'
            },
        ],
        mssp: [{
                id: 1,
                title: 'Security Monitoring and Incident Management',
                content: 'In IT security and risk management, security monitoring and incident management have become more important.',
                link: 'managedservice/mssp#security-monitoring-and-incident-management',
            },
            {
                id: 2,
                title: 'Network Security',
                content: 'Network security refers to the protecting of the underlying networking infrastructure against unauthorized access, misuse, or theft.',
                link: 'managedservice/mssp#network-security'
            },
            {
                id: 3,
                title: 'Vulnerability Management',
                content: 'Vulnerability management is the process of identifying, evaluating, and reporting on security vulnerabilities in systems.',
                link: 'managedservice/mssp#vulnerability-management'
            },
            {
                id: 4,
                title: 'Endpoint Security',
                content: "Endpoint protection software defends your company's devices against malware and dangerous apps, as well as investigating security events and alarms",
                link: 'managedservice/mssp#endpoint-security'
            },
            {
                id: 5,
                title: 'Identity & Access Management ',
                content: 'For defending the hybrid multi - cloud organization, identity and access management (IAM) is critical. ',
                link: 'managedservice/mssp#identity-access-management',
            },
        ],
        managedIt: [{
                id: 1,
                title: 'Software installation and support',
                content: `Many people with coding skills and the willingness to learn new
              programs are represented on our team.`,
                link: 'managedservice/managedIt#Software-Installation-And-Support',
            },
            {
                id: 2,
                title: 'User management, authentication, and single sign-on',
                content: `Single sign-on authentication has fundamentally altered how people
            use the internet, eliminating the old login method.`,
                link: 'managedservice/managedIt#User-Management-Authentication-And-Single-Sign-on'
            },
            {
                id: 3,
                title: `Data storage, warehousing, and management`,
                content: `Data warehousing is an approach that identifies an organization's
            most significant data and generates a unique source of information
            sharing.`,
                link: 'managedservice/managedIt#Data-Storage-Warehousing-And-Management'
            },
            {
                id: 4,
                title: `Data backup and recovery`,
                content: `Not all data loss possibilities are the same, it's critical to pick
            a backup solution that can handle a wide range of restore and
            recovery demands while also reducing recovery time.`,
                link: 'managedservice/managedIt#Data-Backup-And-Recovery'
            },
            {
                id: 5,
                title: `Mobile device management`,
                content: `IT professionals may use Mobile Device Management (MDM) to securely
            monitor and control mobile devices that access critical company
            data.`,
                link: 'managedservice/managedIt#Mobile-Device-Management',
            },
        ],
        cyber: [{
                id: 1,
                title: 'Application Security Testing',
                description: 'Organizations utilize a variety of ways to detect and eradicate vulnerabilities in their software, which is referred to as application security testing. ',
                link: '/managedservice/cybersecurity#application-security-testing',
            },
            {
                id: 2,
                title: 'Dark Web Monitoring',
                description: 'On difficult-to-find dark web sites and forums,identity thieves can buy or sell your personal information. ',
                link: '/managedservice/cybersecurity#dark-web-monitoring'
            },
            {
                id: 3,
                title: 'Endpoint Security',
                description: "Endpoint Protection Solution defends your company's devices against malware and harmful applications, as well as investigating security incidents and alarms",
                link: '/managedservice/cybersecurity#endpoint-security'
            },
            {
                id: 4,
                title: 'Automotive Cyber Security',
                description: 'cybersecurity refers to the protection of electronic systems, software, users, and underlying data against malicious assaults.',
                link: '/managedservice/cybersecurity#automative-cyber-security'
            },
            {
                id: 5,
                title: 'Risk Assessment &  Monitoring Service',
                description: 'Detect a larger number of threats Decrease the amount of time it takes to respond to an assault.Observe industry and regulatory guidelines',
                link: '/managedservice/cybersecurity#risk-assessment-monitoring-services',
            },
        ],
        vCISO: [{
                id: 1,
                title: 'Full-Scale vCISO',
                description: 'A vCISO provides these vital skills for smaller businesses who don’t yet have the resources available to take on an expensive executive.',
                link: 'managedservice/vciso#full-scale-vciso'
            },
            {
                id: 2,
                title: 'Half-Scale vCISO',
                description: 'The Half-Scale vCISO provides security functions and allowing the organization to continually function until a full-time CISO can be hired.',
                link: 'managedservice/vciso#half-scale-vciso'
            },
            {
                id: 3,
                title: 'On-Demand vCISO',
                description: 'The On-Demand vCISO provides guidance and recommendations to address security functions,and assist with contractual security requirements.',
                link: 'managedservice/vciso#on-demand-vciso'
            },
        ],
        clouds: [{
                id: 1,
                title: 'Cloud Security Assessment',
                content: [
                    'Analysis of infrastructure',
                    'Detailed Guidance',
                    'Actionable recommendations',
                ],
                link: 'managedservice/cloudsecurity#cloud-security-assessment',
            },
            {
                id: 2,
                title: 'Cloud Security Advisory Services',
                description: 'Aspire Tech as-Service may help you maintain compliance by validating your compliance and controls.',
                link: 'managedservice/cloudsecurity#cloud-security-advisory-services',
            },
            {
                id: 3,
                title: 'Cloud Protection Services',
                description: "You'll have the control and comfort you need to operate your business securely with Aspire tech, the most versatile and secure.",
                link: 'managedservice/cloudsecurity#cloud-protection-services',
            },
            {
                id: 4,
                title: 'Cloud Security Monitoring',
                description: 'A security solution must cover all components of the cloud, including cloud infrastructure, to enable effective cloud security monitoring etc. ',
                link: 'managedservice/cloudsecurity#cloud-security-monitoring',
            },
        ],
        forensics: [{
                id: 1,
                title: 'Digital evidence recovery',
                description: 'Computer Evidence Recovery uses advanced computer technology and procedures to monitor and identify.',
                link: 'managedservice/digitalforensic#digital-evidence-recovery',
            },
            {
                id: 2,
                title: 'Data breach forensics',
                description: "It's a question of when your company will be hacked. When a firm experiences a data breach, no matter how big or little.",
                link: 'managedservice/digitalforensic#data-breach-forensic',
            },
            {
                id: 3,
                title: 'Cyber Fraud Investigation',
                description: 'A digital crime, often known as a cybercrime, is a crime committed using a computer, phone, or other digital device linked to a network.',
                link: 'managedservice/digitalforensic#cyber-fraud-investigation'
            },
            {
                id: 4,
                title: 'E-discovery',
                description: 'The digital part of identifying, gathering, and providing electronically stored information  in response to a production request in a  inquiry is called eDiscovery.',
                link: 'managedservice/digitalforensic#e-discovery'
            },
            {
                id: 5,
                title: 'Incident response',
                description: 'To avoid security events from escalating, get rapid containment and experienced remediation help.',
                link: 'managedservice/digitalforensic#incident-response'
            },
        ],
        consultancy: [{
                id: 1,
                title: 'Cyber Security Strategy Development',
                description: "A cybersecurity plan doesn't have to be great; it only has to be proactive, effective, well-supported, and improving rapidly.",
                link: 'managedservice/consultancy#cyber-security-strategy-development',
            },
            {
                id: 2,
                title: 'Cyber Security Health Check',
                description: 'It is critical for laying a firm foundation for your cybersecurity architecture and will support you in evaluating your most vulnerable security areas.',
                link: 'managedservice/consultancy#cyber-security-health-check',
            },
            {
                id: 3,
                title: 'Security Policy Development',
                description: "The application of all controls included with prevention associated with an organization's Information Security Plan is determined by security policies.",
                link: 'managedservice/consultancy#security-policy-development',
            },
            {
                id: 4,
                title: 'ISO 27001:2013 Certification',
                description: "The ISO 27001:2013 standard lays out the standards for designing, implementing, maintaining, and developing an organization's information security policies and procedures.",
                link: 'managedservice/consultancy#iso-27001-2013-certification',
            },
            {
                id: 5,
                title: 'Physical Security Assessment',
                description: 'Aspire Tech conducts a physical security analysis by walking around chosen places and identifying if physical security mechanisms are in place, effective, and suitable.',
                link: 'managedservice/consultancy#physical-security-assessment',
            },
        ],
    },
    contactInfo: [

        {
            id: 1,
            phone: '+1.917.600.9233',
            email: 'info@aspiretss.com',
            // location: '2 Ocean Drive, Brooklyn, NY 11224, USA',
            location: '11 Broadway, New York, NY  10004, USA',
            continental: 'USA',
            office: 'Head Office:',
        },
        {
            id: 2,
            phone: '+88-01758114433',
            email: 'info@aspiretss.com',
            location: '60/2(New), Nayapaltan, PO-GPO, PS-Paltan, Dhaka-1000, Bangladesh',
            office: 'Bangladesh Office',
            continental: 'Asia'
        },
        {
            id: 3,
            phone: '+88-01758114433',
            email: 'info@aspiretss.com',
            location: 'Bangabandhu Hi-Tech City, Kaliakoir, Gazipur, Dhaka, Bangladesh',
            office: 'Regional SOC',
            continental: 'Asia'
        },
        {
            id: 4,
            phone: '+44 7448 527546',
            email: 'info@aspiretss.com',
            location: 'Fortis House, 160 London Rd, Barking IG11 888, London, UK',
            continental: 'Europe',
            office: 'UK Office',
        },
        {
            id: 5,
            phone: '+90 552 745 31 79',
            email: 'info@aspiretss.com',
            location: 'Akçalar Mahallesi̇, Atatürk Caddesi̇, No 32, Blok D, Dai̇re 14 Ni̇lüfer Bursa, Turkey',
            continental: 'Asia',
            office: 'Turkey Office',
        },
        {
            id: 6,
            phone: '+491634470827',
            email: 'info@aspiretss.com',
            continental: 'Europe',
            location: 'Im Hoflehen 13, 78098, Triberg im Schwarzwald, Germany',
            office: 'Germany Office',
        },
    ],
    /*  events: {
          upcoming: {
              title: 'DIGITAL SECURITY SUMMIT 2022',
              desc: 'Digital Security Summit is the nation’s most innovative cyber security-technology event in Bangladesh, offering unique educational, collaborative, and workforce development host by Aspire Tech.',
              img: eventImg,
          },

          allEvents: [{
                  id: 1,
                  title: 'Free Microsoft Cloud Training (Virtual)',
                  desc: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece.',
                  img: slideImg1,
                  date: '06 Nov, 2021',
                  time: '06:30 (GMT+6)',
              },
              {
                  id: 2,
                  title: 'Free Microsoft Cloud Training (Virtual)',
                  desc: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece.',
                  img: slideImg2,
                  date: '06 Nov, 2021',
                  time: '06:30 (GMT+6)',
              },
              {
                  id: 3,
                  title: 'Free Microsoft Cloud Training (Virtual)',
                  desc: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece.',
                  img: slideImg1,
                  date: '06 Nov, 2021',
                  time: '06:30 (GMT+6)',
              },
          ],
      },*/
    partners: {
        partnerGroup: {
            groupOne: [{
                    id: 1,
                    img: partner1,
                },
                {
                    id: 2,
                    img: partner2,
                },
                {
                    id: 3,
                    img: partner3,
                },
                {
                    id: 4,
                    img: partner4,
                },
                {
                    id: 5,
                    img: partner5,
                },
                {
                    id: 6,
                    img: partner6,
                },
                {
                    id: 7,
                    img: partner7,
                },
                {
                    id: 8,
                    img: partner8,
                },
                {
                    id: 9,
                    img: partner9,
                },
                {
                    id: 10,
                    img: partner10,
                },
                {
                    id: 11,
                    img: partner11,
                },
                {
                    id: 12,
                    img: partner12,
                },
                {
                    id: 13,
                    img: partner13,
                },
                {
                    id: 14,
                    img: partner14,
                },
                {
                    id: 15,
                    img: partner15,
                },
                {
                    id: 16,
                    img: partner16,
                },
                {
                    id: 17,
                    img: partner17,
                },
                {
                    id: 18,
                    img: partner18,
                },
                {
                    id: 19,
                    img: partner19,
                },
                {
                    id: 20,
                    img: partner20,
                },
                {
                    id: 21,
                    img: partner21,
                },
                {
                    id: 22,
                    img: partner22,
                },
                {
                    id: 23,
                    img: partner23,
                },
                {
                    id: 24,
                    img: partner24,
                },
            ],
            groupTwo: [{
                    id: 25,
                    img: partner25,
                },
                {
                    id: 26,
                    img: partner26,
                },
                {
                    id: 27,
                    img: partner27,
                },
                {
                    id: 28,
                    img: partner28,
                },
                {
                    id: 29,
                    img: partner29,
                },
                {
                    id: 30,
                    img: partner30,
                },
                {
                    id: 31,
                    img: partner31,
                },
                {
                    id: 32,
                    img: partner32,
                },
                {
                    id: 33,
                    img: partner33,
                },
                {
                    id: 34,
                    img: partner34,
                },
                {
                    id: 35,
                    img: partner35,
                },
                {
                    id: 36,
                    img: partner36,
                },
                {
                    id: 37,
                    img: partner37,
                },
                {
                    id: 38,
                    img: partner38,
                },
                {
                    id: 39,
                    img: partner39,
                },
                {
                    id: 40,
                    img: partner40,
                },
                {
                    id: 41,
                    img: partner41,
                },
                {
                    id: 42,
                    img: partner42,
                },
                {
                    id: 43,
                    img: partner43,
                },
                {
                    id: 44,
                    img: partner44,
                },
                {
                    id: 45,
                    img: partner45,
                },
                {
                    id: 46,
                    img: partner46,
                },
                {
                    id: 47,
                    img: partner47,
                },
                {
                    id: 48,
                    img: partner48,
                },
            ],
            groupThree: [{
                    id: 49,
                    img: partner49,
                },
                {
                    id: 50,
                    img: partner50,
                },
                {
                    id: 51,
                    img: partner51,
                },
                {
                    id: 52,
                    img: partner52,
                },
                {
                    id: 53,
                    img: partner53,
                },
                {
                    id: 54,
                    img: partner54,
                },
                {
                    id: 55,
                    img: partner55,
                },
                {
                    id: 56,
                    img: partner56,
                },
                {
                    id: 57,
                    img: partner57,
                },
                {
                    id: 58,
                    img: partner58,
                },
                {
                    id: 59,
                    img: partner59,
                },
                {
                    id: 60,
                    img: partner60,
                },
                {
                    id: 61,
                    img: partner61,
                },
                {
                    id: 62,
                    img: partner62,
                },
                {
                    id: 63,
                    img: partner63,
                },
                {
                    id: 64,
                    img: partner64,
                },
                {
                    id: 65,
                    img: partner65,
                },
                {
                    id: 66,
                    img: partner66,
                },
                {
                    id: 67,
                    img: partner67,
                },
                {
                    id: 68,
                    img: partner68,
                },
                {
                    id: 69,
                    img: partner69,
                },
                {
                    id: 70,
                    img: partner70,
                },
                {
                    id: 71,
                    img: partner71,
                },
                {
                    id: 72,
                    img: partner72,
                },
            ],
        },
        SOCCertification: [{
                id: 1,
                title: 'CISSP',
                img: SOC1,
            },
            {
                id: 2,
                title: 'GCIA',
                img: SOC2,
            },
            {
                id: 3,
                title: 'GCIH',
                img: SOC3,
            },
            {
                id: 4,
                title: 'GCFA',
                img: SOC4,
            },
            {
                id: 5,
                title: 'OSCP',
                img: SOC5,
            },
            {
                id: 6,
                title: 'GWAPT',
                img: SOC6,
            },
            {
                id: 7,
                title: 'Security +',
                img: SOC7,
            },
            {
                id: 8,
                title: 'GSLC',
                img: SOC8,
            },
            {
                id: 9,
                title: 'GPEN',
                img: SOC9,
            },
            {
                id: 10,
                title: 'ISO-27001',
                img: SOC10,
            },
            {
                id: 11,
                title: 'ISO-901',
                img: SOC11,
            },
            {
                id: 12,
                img: SOC12,
            },
            {
                id: 13,
                img: SOC13,
            },
            {
                id: 14,
                img: SOC14,
            },
            {
                id: 15,
                img: SOC15,
            },
        ],
    },
    securityCompliance: [{
            id: 0,
            title: 'PCI DSS',
            desc: 'The PCI (Payment Card Industry) is a sector within the financial industry that is responsible for all electronic payments. As purchases are completed through debit, credit, ATM, POS, prepaid and e-purse systems, sensitive financial data is constantly being transmitted to all parts of the world.',
            img: SCA1,
            icon: SCA1icon,
        },
        {
            id: 1,
            title: 'SWIFT',
            desc: 'The SWIFT is a sector within the financial industry that is responsible for all electronic payments. As purchases are completed through debit, credit, ATM, POS, prepaid and e-purse systems, sensitive financial data is constantly being transmitted to all parts of the world.',
            img: SCA2,
            icon: SCA2icon,
        },
        {
            id: 2,
            title: 'GDPR',
            desc: 'The GDPR is a sector within the financial industry that is responsible for all electronic payments. As purchases are completed through debit, credit, ATM, POS, prepaid and e-purse systems, sensitive financial data is constantly being transmitted to all parts of the world.',
            img: SCA3,
            icon: SCA3icon,
        },
        {
            id: 3,
            title: 'ISO-27001',
            desc: 'The ISO-27001 is a sector within the financial industry that is responsible for all electronic payments. As purchases are completed through debit, credit, ATM, POS, prepaid and e-purse systems, sensitive financial data is constantly being transmitted to all parts of the world.',
            img: SCA4,
            icon: SCA4icon,
        },
    ],
    cloudSecurity: [{
            id: 0,
            title: 'DEV SEC OPS',
            desc: [
                'Secure Software-Defined Lifecycle',
                'IaC Azure Pipelines',
                'Kubernetes on Azure',
                'Azure Policy and Azure Security Center',
                'Git Pre-commit hooks',
                'CI/CD automation testing',
                'Database encryption',
                'Threat modeling and monitoring',
                'IDE security plug-ins and pre-commit hooks',
                'Peer reviews and secure coding standards',
            ],
            img: cloudItem1,
            icon: cloudIcon1,
            link: 'cloudsecurity/dev-sec-ops',
            sections: 'Cloud Security Automation',
            topics: 'DevSecOps'
        },
        {
            id: 1,
            title: 'Microsoft Sentinel',
            desc: [
                'Cloud-based SIEM',
                'Next-generation SOC with cloud and AI',
                'Automate common tasks and threat response',
                'Security Orchestration and Automation',
                'Alert Visibility, Analytics, Threat Hunting',
                'Behavior analytics',
                'Collect data across your enterprise',
                'Analyze and detect threats quickly with AI',
                'Investigate and hunt for suspicious activities',
                'Seamless integration of on-prem and cloud',
            ],
            img: cloudItem2,
            icon: cloudIcon2,
            link: 'cloudsecurity/microsoft-sentinel',
            sections: 'Cloud Security Automation',
            topics: 'Microsoft Sentinel'
        },
        {
            id: 2,
            title: 'XDR',
            desc: [
                'Azure Defender for Servers',
                'Azure Defender for IoT',
                'Azure Defender for SQL',
                'Azure Active Directory',
                'Microsoft Cloud App Security',
                'Microsoft Defender for Endpoints',
                'Microsoft Defender for Identity',
                'Microsoft Defender for Office 365',
                'Microsoft Endpoint Manager',
            ],
            img: cloudItem3,
            icon: cloudIcon3,
            link: 'cloudsecurity/xdr',
            sections: 'Cloud Security Automation',
            topics: 'XDR'
        },
        {
            id: 3,
            title: 'Microsoft Defender For Cloud',
            desc: [
                'Microsoft Defender for Endpoint',
                'Vulnerability assessment for VM, container & SQL',
                'Multi-cloud security',
                'Hybrid security',
                'Threat protection alerts',
                'Track compliance with a range of standards',
                'Access and application controls',
                'Container security features',
                'Threat protection for resources connected to Azure',
            ],
            img: cloudItem4,
            icon: cloudIcon4,
            link: 'cloudsecurity/microsoft-defender-for-cloud',
            sections: 'Cloud Security Automation',
            topics: 'Microsoft Defender For Cloud'
        },
        {
            id: 4,
            title: 'Azure Active Directory',
            desc: [
                'Application Management',
                'Authentication',
                'Azure Active Directory for Developers',
                'Business-to-Business (B2B)',
                'Business-to-Customer (B2C)',
                'Device Management',
                'Hybrid Identity',
                'Identity  Governance',
                'Identity  Protection',
                'Reports and Monitoring',
            ],
            img: cloudItem5,
            icon: cloudIcon5,
            link: 'cloudsecurity/azure-active-directory',
            sections: 'Cloud Security Automation',
            topics: 'Azure Active Directory'
        },
        {
            id: 5,
            title: 'Network Security',
            desc: [
                `Azure Firewall`, 
                `Secure Remote Access`, 
                `Cross-premises Connectivity`, 
                `Perimeter Network (DMZ)`, 
                `Azure DDoS Protection`, 
                `Azure Web Application Firewall`, 
                `Azure Firewall Manager`, 
                `Network Watcher`, 
                `Content Delivery Network`, 
                `Monitoring and Threat Detection `,
            ],
            img: cloudItem6,
            icon: cloudIcon6,
            link: 'cloudsecurity/network-security',
            sections: 'Cloud Security Automation',
            topics: 'Network Security'
        },
        {
            id: 6,
            title: 'Governance',
            desc: [
                `Azure Management Groups`,
                `Create Hierarchies`, 
                `Azure Policy`, 
                `Azure Blueprints`, 
                `Add Policies and Roles to Templates`, 
                `Do Inventory Management`, 
                `Cost Management`, 
                `Security Baseline`, 
                `Identity Baseline`, 
                `Deployment Acceleration`
            ],
            img: cloudItem7,
            icon: cloudIcon7,
            link: 'cloudsecurity/governance',
            sections: 'Cloud Security Automation',
            topics: 'Governance'

        },
        {
            id: 7,
            title: 'Storage Security',
            desc: [
                `Data Protection`, 
                `Identity and Access Management`, 
                `Authorize access to Data`, 
                `Restrict access using SAS`, 
                `Authorize with Shared Key`, 
                `Authorize with Azure AD`, 
                `Use Soft Delete for Blob Data`, 
                `Restrict Network Access using Endpoints`, 
                `Storage Account Firewall`, 
                `Encryption in Transit`
            ],
            img: cloudItem8,
            icon: cloudIcon8,
            link: 'cloudsecurity/storage-security',
            sections: 'Cloud Security Automation',
            topics: 'Storage Security'
        },
        {
            id: 8,
            title: 'Data Security',
            desc: [                
                `Protect Data at Rest`, 
                `Protect Data in Transit`, 
                `Secure Email, Documents, and Sensitive Data`, 
                `Azure Disk Encryption`, 
                `Client-Side Encryption`, 
                `Server-Side Encryption`, 
                `Azure Storage Service Encryption`, 
                `Transparent Data Encryption`, 
                `Cell-level or Column-Level Encryption`, 
                `Always Encrypted Feature`,
            ],
            img: cloudItem9,
            icon: cloudIcon9,
            link: 'cloudsecurity/data-security',
            sections: 'Cloud Security Automation',
            topics: 'Data Security'

        },
    ],
    ourSolutions: [{
            id: 1,
            title: 'SIEM',
            desc: 'SIEM stands for security information and event management, and it is a single security management system that provides full visibility into network activities, allowing you to respond to attacks in real-time. It gathers, parses, and categorizes machine data from a variety of sources, then analyzes the information to deliver insights so you may take appropriate action.',
            img: siem,
            // categories:["all", "cyber", "cloud","digital forensic"],
            categories: ['all', 'cyber'],
            link: '/cybersecurity/siem',
        },
        {
            id: 2,
            title: 'DevSecOps',
            desc: 'DevSecOps, often known as secure DevOps, is a software development approach in which everyone is accountable for app security. Development teams seek to create safer software with more speed and efficiency by integrating developers with IT operations and focusing everyone on making better security decisions.',
            img: DevSecOps,
            categories: ['all', 'cyber'],
            link: '/cybersecurity/devsecops',
        },
        {
            id: 3,
            title: 'Microsoft Sentinel',
            desc: `In Microsoft's public cloud platform, Azure Sentinel is a SIEM (Security Information and Event Management) and SOAR (Security Orchestration and Automated Response) system. It can handle alert detection, threat visibility, proactive hunting, and threat response all in one package.`,
            img: Microsoft_Sentinel,
            categories: ['all', 'cloud'],
            link: '/cloudautomation/MicrosoftSentinel',
        },
        {
            id: 2,
            title: 'DevSecOps',
            desc: 'DevSecOps, often known as secure DevOps, is a software development approach in which everyone is accountable for app security. Development teams seek to create safer software with more speed and efficiency by integrating developers with IT operations and focusing everyone on making better security decisions.',
            img: DevSecOps,
            categories: ['cloud'],
            link: 'cybersecurity/devsecops',
        },
        {
            id: 4,
            title: 'Vpn Gateway',
            desc: 'DevSecOps, often known as secure DevOps, is a software development approach in which everyone is accountable for app security. Development teams seek to create safer software with more speed and efficiency by integrating developers with IT operations and focusing everyone on making better security decisions.',
            img: vpn_gateway,
            categories: ['all', 'cloud'],
            link: '/cybersecurity/vpn-gateway',
        },
        {
            id: 5,
            title: 'ESR/MDR/XDR',
            desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum It is a long established fact that a reader will be distracted by the readable.It is a long established fact that a reader.',
            img: xdr,
            categories: ['all', 'cyber', 'cloud'],
            link: '/cloudautomation/XDR',
        },
        {
            id: 6,
            title: 'Azure Information Security',
            desc: 'DevSecOps, often known as secure DevOps, is a software development approach in which everyone is accountable for app security. Development teams seek to create safer software with more speed and efficiency by integrating developers with IT operations and focusing everyone on making better security decisions.',
            img: Azure_Information_Protection,
            categories: ['all', 'cloud'],
            link: '/cloudSecurity/azure-information-security',
        },
        {
            id: 7,
            title: 'Digital Evidence Recovery',
            desc: 'Digital Forensics Services helps the forensic team to analyzes, inspect, identify, and preserve the digital evidence residing on various types of electronic devices.',
            img: digital_evidence,
            categories: ['all', 'digital forensic'],
            link: '/managedservice/digitalforensic#digital-evidence-recovery',
        },
        {
            id: 8,
            title: 'Microsoft Defender',
            desc: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum It is a long established fact that a reader will be distracted by the readable.It is a long established fact that a reader.',
            img: Microsoft_Defender,
            categories: ['all', 'cloud', 'cyber'],
            link: '/cloudautomation/MicrosoftDefenderForCloud',
        },
        {
            id: 9,
            title: 'SOC As A Service ',
            desc: 'SOC as a Service or Security Operations Center as a Service, are security operations (SecOps) services that help internal security and IT teams manage and monitor logs, devices, clouds, networks, and assets.',
            img: SOC_As_a_Service,
            categories: ['all', 'managed services'],
            link: '/managedservice/SOCaasServices',
        },
        {
            id: 10,
            title: 'VAPT',
            desc: `VAPT stands for Vulnerability Assessment and Penetration Testing. It's a type of security testing that looks for flaws in an application, network, endpoint, or cloud. Both Vulnerability Assessment and Penetration Testing have distinct advantages, and they are frequently combined to produce a comprehensive analysis.`,
            img: vapt,
            categories: ['all', 'cyber'],
            link: '/cybersecurity/vulnerability-assessment',
        },
        {
            id: 11,
            title: 'Data Breach Forensic',
            desc: `Digital forensics is becoming one of the most important tools that companies can use to piece together clues about the size and scope of a data breach as they work to mitigate the damage, meet legal and regulatory requirements, and reassure customers that they are taking steps to help prevent such a breach from happening again in the increasingly complicated landscape of data breaches.`,
            img: data_breach,
            categories: ['all', 'digital forensic'],
            link: '/managedservice/digitalforensic#data-breach-forensic',
        },
        {
            id: 12,
            title: 'Zero Trust',
            desc: 'A zero-trust model is a security framework that strengthens an organization by eliminating implicit trust and requiring strict user and device authentication across the network. Trust is created depending on context (e.g., user identification and location, the security posture of the endpoint, the app or service being requested) with policy checks at each stage, following the zero trust principle of least-privileged access.',
            img: zero_trust,
            categories: ['all', 'cyber'],
            link: '/cybersecurity/zero-trust',
        },
        {
            id: 13,
            title: 'vCISO Services',
            desc: 'A virtual chief information security officer, or vCISO, is a professional who works with firms on a regular basis to give all of the critical cyber security support that an in-house senior executive would provide, but in a more efficient and cost-effective manner.',
            img: vCISO_Services,
            categories: ['all', 'managed services'],
            link: '/managedservice/vciso',
        },
        {
            id: 14,
            title: 'Cyber Fraud Investigation',
            desc: 'Aspire Tech Cyber Fraud Investigation digital and cyber enabled crimes such as hacking incidents, data breaches, phishing attacks, email fraud, financial crimes, online scams, and cyber attacks. Our immediate response to such incidents, as well as our reports and results, allow law enforcement to move quickly with their investigations.',
            img: cyber_fraud,
            categories: ['all', 'digital forensic'],
            link: '/managedservice/digitalforensic#cyber-fraud-investigation',
        },
        {
            id: 15,
            title: 'Data Loss Prevention',
            desc: 'Adaptive data loss prevention (A-DLP or DLP) is a simple solution that allows enterprises to get better control of their essential information and gain more visibility while preventing business interruptions.',
            img: Data_Loss_Prevention,
            categories: ['all', 'cyber'],
            link: '/cybersecurity/data-loss-prevention',
        },
        {
            id: 16,
            title: 'Incident response',
            desc: `Aspire Tech takes pride in being a leader in incident response, bringing order, stability, and control to what could otherwise be a chaotic situation. Aspire Tech collaborates closely with enterprises to create IR plans that are specific to their organization's structure and capabilities.`,
            img: Incident_Response,
            categories: ['all', 'digital forensic'],
            link: '/managedservice/SOCaasServices#incident-response',
        },
        {
            id: 17,
            title: 'Cloud Security Services',
            desc: `Cloud Security Services is the application of cybersecurity practices and programs to the protection of data and applications on public and private cloud platforms. Cloud security helps businesses to address both old cybersecurity risks and new cloud-related challenges.`,
            img: Cloud_Security_Services,
            categories: ['all', 'managed services'],
            link: '/managedservice/cloudsecurity',
        },
        {
            id: 18,
            title: 'Email Security',
            desc: `Email security refers to all of the safeguards in place to keep an email account or service's content and access secure. It allows a person or organization to control who has access to one or more email addresses/accounts.`,
            img: Email_Security,
            categories: ['all', 'cyber'],
            link: '/cybersecurity/email-security',
        },
        {
            id: 19,
            title: 'DDoS Protection',
            desc: 'DDoS (distributed denial of service) attacks are a type of DoS attack. A DDoS attack involves the usage of a botnet, which is a collection of connected online devices that are utilized to flood a target website with fake traffic.',
            img: DDoS_Protection,
            categories: ['all', 'cyber'],
            link: 'cybersecurity/ddos-protection',
        },
        {
            id: 20,
            title: 'Insider Risk Mangement',
            desc: `An Insider Risk Management is a malevolent, thoughtless, or negligent threat to an organization that originates from within the organization, such as employees, former employees, contractors, or business associates, who have inside knowledge of the organization's security processes, data, and computer systems.`,
            img: Insider_Risk_Manager,
            categories: ['all', 'cyber'],
            link: 'cybersecurity/insider-risk-management',
        },
        {
            id: 21,
            title: 'Ransomeware Prevention',
            desc: `Ransomware is a type of highly destructive virus that infects network equipment and data centers. Cybercriminals attempt to exploit system and security flaws in order to obtain access to your company's data, encrypt it, and then demand payment to decrypt it. Ransomware has grown in volume, frequency, and strength throughout the years.`,
            img: Ransomware_Prevention,
            categories: ['all', 'cyber'],
            link: 'cybersecurity/ransomware-prevention',
        },
        {
            id: 22,
            title: 'E-discovery',
            desc: `As part of a litigation or investigation, eDiscovery software allows legal experts to analyze, evaluate, tag, and create electronic documents. Attorneys can use the correct software to find vital information about a case while lowering expenses, speeding up resolutions, and decreasing risks.`,
            img: E_Discovery,
            categories: ['all', 'digital forensic'],
            link: '/managedservice/digitalforensic#e-discovery',
        },
        {
            id: 23,
            title: 'Digital Forensics Services',
            desc: `Digital Forensics Services helps the forensic team to analyzes, inspect, identify, and preserve the digital evidence residing on various types of electronic devices.`,
            img: Digital_Forensic_Services,
            categories: ['all', 'managed services'],
            link: '/managedservice/digitalforensic',
        },
        {
            id: 24,
            title: 'MSSP Services',
            desc: `Outsourced monitoring and management of security devices and systems is provided by a Managed Security Service Provider (MSSP). Managed firewall, intrusion detection, virtual private network, vulnerability screening, and antiviral services are all common services.`,
            img: MSSP_Services,
            categories: ['all', 'managed services'],
            link: '/managedservice/mssp',
        },
    ],
};