import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { FaGlobe } from 'react-icons/fa';
import LogInModal from '../modal/LogInModal';
import RegisterModal from '../modal/RegisterModal';
import { NavHashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const TopBar = () => {
    const [loginModal, setLoginModal] = useState(false);
    const [registrationModal, setRegistrationModal] = useState(false);
    const [language, setLanguage] = useState('EN');
    const location = useLocation(); //for current url location//


    return (
      <div className = "topbar d-flex flex-wrap align-items-center justify-content-between">
          <div className="topbar__intro d-flex flex-wrap align-items-center h-100 px-4" >
            <div className="recentEvent d-flex align-items-center" style={{height:"48px"}}>
              <marquee className="text-warning ps-3" style={{fontSize:"18px",width:"300px", background:"none", overflow:"hidden"}}>
                Free Microsoft Cloud Training (Virtual)
              </marquee>
            </div>
            <div className="staticText ms- 0 ms-md-3 text-decoration-underline" style={{cursor:"pointer", height:"30px" }}
            onClick={() => setRegistrationModal(true)}
            >
              Register Now
            </div>
          </div>
          
          <div className="topbar__menu px-4">
            <ul className="d-flex justify-content-end align-items-center p-0 pe-md-4">
              <li>
                { location.pathname === '/' ?
                  <NavHashLink to='#get-in-touch' className="me-3">Contact</NavHashLink> 
                  : <Link to="/company/contact" >Contact</Link>
                }
              </li>
              {/* <li>
                <NavHashLink to='#get-in-touch' className="me-3">Support</NavHashLink>
              </li> */}
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="transparent" className="border-0 me-3">
                    <FaUser className="mb-1" />
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setLoginModal(true)}>
                      Login
                    </Dropdown.Item>
                  </Dropdown.Menu> */}
                </Dropdown>
              </li>
              <li>
                <Dropdown>
                  <Dropdown.Toggle variant="transparent" className="border-0">
                    <FaGlobe className="mb-1" /> {language}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => setLanguage('EN')}>
                      EN
                    </Dropdown.Item>
                    
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          {loginModal && (
            <LogInModal
              show={loginModal}
              handleClose={() => setLoginModal(false)}
            />
          )}
          {registrationModal && (
            <RegisterModal
              show={registrationModal}
              handleClose={() => setRegistrationModal(false)}
            />
          )}
          </div>
      </div>
    );
};

export default TopBar;