import React from 'react'
import { Helmet } from 'react-helmet'
import BannerSection from './frames/BannerSectionWithoutBtn'
import {Col, Card, Container, Row, Image, ListGroup, Button} from 'react-bootstrap';
import SideNavHeader from './SideNavHeader';
import BlogSideNav from './BlogSideNav';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useState } from 'react';
import {
    BsFillCalendar2CheckFill,
    BsFillClockFill,
    BsClock,
    BsLaptop,
    BsCalendarCheck,
    BsCurrencyExchange,
    BsPencilSquare, BsEnvelope, BsTelephone
} from "react-icons/bs";
import {IoLogoFacebook} from "react-icons/fa";
import Facebook from "../../assets/images/facebook.png"
import LinkedIn from "../../assets/images/in.png"
import Twitter from "../../assets/images/twitter.png"
import bannerImg from "../../assets/images/banner-img.webp";
import moment from 'moment';

const WebinarDetails = () => {
    const location = useLocation();
    const id = new URLSearchParams(location.search).get('id');


    const [webinarDetail, setWebinarDetail] = useState([]);
    useEffect(() => {
        fetch(`https://backend.aspiretss.com/api/webinar-get-singal-data/${id}`)
            //fetch(`http://localhost:8000/api/webinar-get-singal-data/${id}`)
            .then(res=>res.json())
            .then(data => setWebinarDetail(data))
    },[]);
    console.log(webinarDetail);
    const currentDate = new Date(webinarDetail.date);
    console.log(currentDate.toDateString());

    //const webinarDate = moment(webinarDetail.date);


    const data = {
        meta: {
            title: 'Webinar Details',
            desc: ``,
        },
        banner: {
            heading: webinarDetail.name,
            tagLine: '',
        },
    };
    const cardStyle = { flexDirection: "column", minWidth: "0", wordWrap: "break-word", backgroundColor: "#eaf3f6", backgroundClip: "border-box", border: "1px solid rgb(234 243 246)", borderRadius: "0rem", marginTop: "15px" }
    const webinarBg = { backdrop: "#eaf3f6"}
    const webinarText = { backdrop: "#163560 !important"}
    const roundImg = { borderRadius: "65px", height: "115px", width: "115px"}
    return (
        <>
            <Helmet>
                <title>{data.meta.title}</title>
                <meta name="description" content={data.meta.desc} />
                <meta name="keywords" content="" />
            </Helmet>
           {/* <BannerSection banner={data.banner} />{' '}*/}
            <div className="inner-banner py-4 py-md-5 mt-5 px-1">
                <div className="container">
                    <div className="row flex-column-reverse flex-lg-row align-items-center">
                        <div className="col-lg-7 banner-content">
                            <h5 className="banner-title h3">{data.banner.heading}</h5>

                            <p className="font-14 max-475 ">{data.banner.desc}</p>

                        </div>
                        <div className="col-lg-5 banner-image">
                            <figure>
                                <img src={bannerImg} alt="banner-img" className="img-fluid" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <Container className="h-100 mt-5 mb-5">
                <Row>
                    <Col>
                        <Card className="rounded-md" style={{cardStyle, background: "#eaf3f6", border:"0"}}>
                            <Row>
                                {/*1st section*/}
                                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="p-0" style={webinarBg}>
                                    <div className="" style={{width:"265px", height:"700px", background:"#ffffff"}}>
                                        <Image src={webinarDetail.image} height="540px" width="100%" alt="Webinar Image!" className="rounded-md"/>
                                        <h5 className="ps-4 pt-4"><strong><BsCalendarCheck size={18} className="items-center" style={{color: "#0f417a"}} /> </strong><span className="fw-normal ps-2">{webinarDetail.date} </span></h5>
                                        <h5 className="ps-4 pt-2"><BsClock size={18} className="items-center" style={{color: "#0f417a"}} /><span className="fw-normal ps-2">{webinarDetail.time}</span></h5>
                                        <h6 className="ps-4 pt-2"><strong><BsLaptop size={18} className="items-center" style={{color: "#0f417a"}}/> Format : </strong><span className="fw-normal ps-2">{webinarDetail.format}</span></h6>
                                        {/*<h6 className="ps-4 pt-2"><strong><BsCurrencyExchange size={18} className="items-center" style={{color: "#0f417a"}}/> Cost : </strong><span className="fw-normal ps-2">${webinarDetail.cost}</span></h6>*/}
                                        <div className="text-center">
                                            <Link to={`/resources/webinars-registration?id=${webinarDetail.id}`}>
                                                <Button variant="" className="btn btn-success bg-success rounded-0 mt-5 p-3">
                                                    <BsPencilSquare /> Register now
                                                </Button>
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                                {/*2nd section*/}
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="bg-white">
                                    {webinarDetail ?
                                        // render blog post content
                                        <div>
                                            <div>
                                                <Row className="">
                                                    <Col xs sm md lg xl xxl="3">
                                                        <div className="d-flex">
                                                            <p className='px-1 pb-0 pt-5' style={{fontSize: "30px", color:"#163560"}}>{webinarDetail.name}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs sm md lg xl xxl="12">
                                                        <p className='text-dark text-justify p-2 pb-0' dangerouslySetInnerHTML={{__html: webinarDetail.description}} />
                                                    </Col>
                                                </Row>
                                                <Row style={{paddingTop:"100px"}}>
                                                    <Col xs sm md lg xl xxl="12">
                                                        <p className='text-dark text-justify px-1 pb-2'>Share this page:
                                                            <div className="d-flex">
                                                                <p className="text-dark d-flex pt-2 px-1" style={{fontSize: "18px", color:"#163560"}}>
                                                                    <a href="https://www.facebook.com/aspiretss" target="_blank"> <img src={Facebook} style={{height: "37px"}} alt="Facebook"/> </a>
                                                                    <a href="https://www.linkedin.com/company/aspire-tech-services-solutions-ltd/" target="_blank"><img src={LinkedIn} style={{height: "29px", marginTop: "4px", borderRadius: "2px"}} alt="LinkedIn"/></a>
                                                                    <a href="https://www.linkedin.com/company/aspire-tech-services-solutions-ltd/" target="_blank"><img src={Twitter} style={{height: "37px"}} alt="Twitter"/></a>
                                                                </p>
                                                            </div>
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                            {/* <div className='d-flex flex-wrap justify-content-between align-items-center my-5'>
                                            <button type="button" className ="btn btn-primary">Previous</button>
                                            <button type="button" className ="btn btn-primary">Next</button>
                                            </div> */}
                                        </div>

                                        :
                                        // render a "post not found" message
                                        <div className="p-4 mt-3 rounded-2 d-flex align-items-center justify-content-between" >
                                            <p>No Data Found</p>
                                            <Link to={`/resources/blog/`}  type="button" className ="btn btn-primary">Go Back</Link>
                                        </div>
                                    }
                                </Col>
                                {/*3rd section*/}
                                <Col xs={12} sm={12} md={3} lg={3} xl={3} xxl={3} className="p-0" style={webinarBg}>
                                    <div className="ms-4" style={{width:"265px", height:"700px", background:"#ffffff"}}>
                                        <div className="text-center">
                                            <Image roundedCircle  src={webinarDetail.author_image} alt="Webinar Image!" className="mt-3" style={roundImg}/>
                                        </div>
                                        <h3 className="ps-4 pt-4 fw-normal text-center" style={{webinarText}}>Event Contact</h3>
                                        <h6 className="ps-4 pt-2" style={{webinarText}}>{webinarDetail.author_name}</h6>
                                        <p className="ps-4 pt-2" style={{color:"#163560"}}>{webinarDetail.author_designation}</p>
                                        <p className="ps-4 pt-2" style={{webinarText}}><BsEnvelope size={18} style={{color:"#000000"}} /><span className="fw-normal ps-2" style={{color:"#163560"}}>{webinarDetail.author_email}</span></p>
                                        <p className="ps-4 pt-2" style={{webinarText}}><strong><BsTelephone size={18} className="items-center" style={{color: "#0f417a"}}/> </strong><span className="fw-normal ps-2" style={{color:"#163560"}}>{webinarDetail.author_phone}</span></p>
                                    </div>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>

    )
}

export default WebinarDetails